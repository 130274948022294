import {
    GET_LIST_TRANSACTION,
    CLEAR_LIST_TRANSACTION,
     
    GET_TRANSACTION_BY_CLUB,
    GET_TRANSACTION_BY_EMPLOYEE,
    GET_TRANSACTION_MEMBER,
    GET_ORDER_BY_MEMBER,
    GET_UNIT_TRANSACTION,
    GET_REVENUE_UNIT_TRANSACTION,
    GET_POS_TRANSACTION,
    GET_REVENUE_POS_TRANSACTION,
    GET_SALES_TRANSACTION,
    GET_REVENUE_SALES_TRANSACTION,
    GET_BEST_REVENUE_SALES,

    GET_REVENUE_UNIT_PIF_TODAY,
    GET_REVENUE_UNIT_PIF_MTD,
    GET_REVENUE_UNIT_EFT_TODAY,
    GET_REVENUE_UNIT_EFT_MTD,
    GET_REVENUE_NJM_TODAY,
    GET_REVENUE_NJM_MTD,
    GET_REVENUE_POS_TODAY,
    GET_REVENUE_POS_MTD,

    GET_COMPARE_REVENUE_UNIT_PIF_MTD,
    GET_COMPARE_REVENUE_UNIT_EFT_MTD,

    GET_REVENUE_CLUB_FC_FIRST_MONTH,
    GET_REVENUE_CLUB_FC_SECOND_MONTH,
    GET_REVENUE_CLUB_PT_FIRST_MONTH,
    GET_REVENUE_CLUB_PT_SECOND_MONTH,
    GET_REVENUE_CLUB_MARCOMM_FIRST_MONTH,
    GET_REVENUE_CLUB_MARCOMM_SECOND_MONTH,
    GET_REVENUE_CLUB_OPERATION_FIRST_MONTH,
    GET_REVENUE_CLUB_OPERATION_SECOND_MONTH,

    GET_CONDUCT_PT_FIRST_MONTH,
    GET_CONDUCT_PT_SECOND_MONTH,
    GET_CONDUCT_FTHPA_FIRST_MONTH,
    GET_CONDUCT_FTHPA_SECOND_MONTH,

    GET_ORLOG_TRANSACTION,

    GET_HISTORY_TRAINER_PT,
    GET_HISTORY_TRAINER_FT_RFT_HPA,
    GET_RATE_AUTOPAY,

    GET_TRANSACTION_VOID,
    GET_REPORT_RETAINER,
    GET_DETAIL_RETAINER,

    GET_MEMBER_PACKAGE_TRAINER,
    GET_EMPLOYEE_GUEST_CHECKIN,
    GET_FC_REV_PERFORMANCE,
    GET_FC_UNIT_PERFORMANCE,
    GET_FC_PERFORMANCE_LIST,
    GET_LIST_TRANSFER_PACKAGE,
    GET_LIST_EXTEND_PT

} from "../actions/TransactionActions";

const initialState = {
    listTransaction: [],
    clubTransaction: [],
    employeeTransaction: [],
    memberTransaction: [],
    unitEftPifTransaction: [],
    revenueEftPifTransaction: [],
    posTransaction: [],
    revenuePosTransaction: [],
    salesTransaction: [],
    revenueSalesTransaction: [],
    bestRevenueSales: [],

    revenueUnitPif: { today: {}, mtd: {} },
    revenueUnitEft: { today: {}, mtd: {} },
    revenueNJM: { today: {}, mtd: {} },
    revenuePOS: { today: {}, mtd: {} },

    compareRevenueUnitPif: { today: {}, mtd: {} },
    compareRevenueUnitEft: { today: {}, mtd: {} },

    revenueClub: {
        firstMonth: { fc: "", pt: "", marcomm: "", operation: "" },
        secondMonth: { fc: "", pt: "", marcomm: "", operation: "" }
    },

    countConduct: {
        pt: { firstMonth: "", secondMonth: "" },
        fthpa: { firstMonth: "", secondMonth: "" },
    },

    orLogTransaction: [],
    historyTrainerSession: [],
    historyTrainerFtRftHpa: [],
    rateAutopay: [],

    transactionVoid: [],
    reportRetainer: [],
    detailRetainer: [],

    memberPackageTrainer: [],
    employeeGuestCheckin: [],
    fcRevPerformance: [],
    fcUnitPerformance: [],
    fcPerformanceList: [],
    listTransfer: [],
    listExtendPt: [],
};

const TransactionReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LIST_TRANSACTION: {
            return {
                ...state,
                listTransaction: action.data,
            };
        }

        case CLEAR_LIST_TRANSACTION: {
            return {
                ...state, 
                listTransaction: []
            }
        }

        case GET_TRANSACTION_BY_CLUB: {
            return {
                ...state,
                clubTransaction: action.data,
            };
        }

        case GET_TRANSACTION_MEMBER: {
            return {
                ...state,
                memberTransaction: action.data
            }
        }

        case GET_TRANSACTION_BY_EMPLOYEE: {
            return {
                ...state,
                employeeTransaction: action.data,
            };
        }

        case GET_ORDER_BY_MEMBER: {
            return {
                ...state,
                listTransaction: action.data
            }
        }

        case GET_UNIT_TRANSACTION: {
            return {
                ...state,
                unitEftPifTransaction: action.data
            }
        }

        case GET_REVENUE_UNIT_TRANSACTION: {
            return {
                ...state,
                revenueEftPifTransaction: action.data
            }
        }

        case GET_POS_TRANSACTION: {
            return {
                ...state,
                posTransaction: action.data
            }
        }

        case GET_REVENUE_POS_TRANSACTION: {
            return {
                ...state,
                revenuePosTransaction: action.data
            }
        }

        case GET_SALES_TRANSACTION: {
            return {
                ...state,
                salesTransaction: action.data
            }
        }

        case GET_REVENUE_SALES_TRANSACTION: {
            return {
                ...state,
                revenueSalesTransaction: action.data
            }
        }

        case GET_BEST_REVENUE_SALES: {
            return {
                ...state,
                bestRevenueSales: action.data   
            }
        }

        case GET_REVENUE_UNIT_PIF_TODAY: {
            return {
                ...state,
                revenueUnitPif: {
                    ...state.revenueUnitPif,
                    today: action.data
                }
            }
        }

        case GET_REVENUE_UNIT_PIF_MTD: {
            return {
                ...state,
                revenueUnitPif: {
                    ...state.revenueUnitPif,
                    mtd: action.data
                }
            }
        }

        case GET_REVENUE_UNIT_EFT_TODAY: {
            return {
                ...state,
                revenueUnitEft: {
                    ...state.revenueUnitEft,
                    today: action.data
                }
            }
        }

        case GET_REVENUE_UNIT_EFT_MTD: {
            return {
                ...state,
                revenueUnitEft: {
                    ...state.revenueUnitEft,
                    mtd: action.data
                }
            }
        }

        case GET_REVENUE_NJM_TODAY: {
            return {
                ...state,
                revenueNJM: {
                    ...state.revenueNJM,
                    today: action.data
                }
            }
        }

        case GET_REVENUE_NJM_MTD: {
            return {
                ...state,
                revenueNJM: {
                    ...state.revenueNJM,
                    mtd: action.data
                }
            }
        }

        case GET_REVENUE_POS_TODAY: {
            return {
                ...state,
                revenuePOS: {
                    ...state.revenuePOS,
                    today: action.data
                }
            }
        }

        case GET_REVENUE_POS_MTD: {
            return {
                ...state,
                revenuePOS: {
                    ...state.revenuePOS,
                    mtd: action.data
                }
            }
        }

        case GET_COMPARE_REVENUE_UNIT_PIF_MTD: {
            return {
                ...state,
                compareRevenueUnitPif: {
                    ...state.compareRevenueUnitPif,
                    mtd: action.data
                }
            }
        }

        case GET_COMPARE_REVENUE_UNIT_EFT_MTD: {
            return {
                ...state,
                compareRevenueUnitEft: {
                    ...state.compareRevenueUnitEft,
                    mtd: action.data
                }
            }
        }

        case GET_REVENUE_CLUB_FC_FIRST_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    firstMonth: {
                        ...state.revenueClub.firstMonth,
                        fc: action.data
                    }
                }
            }
        }

        case GET_REVENUE_CLUB_FC_SECOND_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    secondMonth: {
                        ...state.revenueClub.secondMonth,
                        fc: action.data
                    }
                }
            }
        }

        case GET_REVENUE_CLUB_PT_FIRST_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    firstMonth: {
                        ...state.revenueClub.firstMonth,
                        pt: action.data
                    }
                }
            }
        }

        case GET_REVENUE_CLUB_PT_SECOND_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    secondMonth: {
                        ...state.revenueClub.secondMonth,
                        pt: action.data
                    }
                }
            }
        }

        case GET_REVENUE_CLUB_MARCOMM_FIRST_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    firstMonth: {
                        ...state.revenueClub.firstMonth,
                        marcomm: action.data
                    }
                }
            }
        }

        case GET_REVENUE_CLUB_MARCOMM_SECOND_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    secondMonth: {
                        ...state.revenueClub.secondMonth,
                        marcomm: action.data
                    }
                }
            }
        }

        case GET_REVENUE_CLUB_OPERATION_FIRST_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    firstMonth: {
                        ...state.revenueClub.firstMonth,
                        operation: action.data
                    }
                }
            }
        }

        case GET_REVENUE_CLUB_OPERATION_SECOND_MONTH: {
            return {
                ...state,
                revenueClub: {
                    ...state.revenueClub,
                    secondMonth: {
                        ...state.revenueClub.secondMonth,
                        operation: action.data
                    }
                }
            }
        }

        case GET_CONDUCT_PT_FIRST_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    pt: {
                        ...state.countConduct.pt,
                        firstMonth: action.data
                    }
                }
            }
        }

        case GET_CONDUCT_PT_SECOND_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    pt: {
                        ...state.countConduct.pt,
                        secondMonth: action.data
                    }
                }
            }
        }

        case GET_CONDUCT_FTHPA_FIRST_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    fthpa: {
                        ...state.countConduct.fthpa,
                        firstMonth: action.data
                    }
                }
            }
        }

        case GET_CONDUCT_FTHPA_SECOND_MONTH: {
            return {
                ...state,
                countConduct: {
                    ...state.countConduct,
                    fthpa: {
                        ...state.countConduct.fthpa,
                        secondMonth: action.data
                    }
                }
            }
        }

        case GET_ORLOG_TRANSACTION: {
            return {
                ...state,
                orLogTransaction: action.data
            }
        }

        case GET_HISTORY_TRAINER_PT: {
            return {
                ...state,
                historyTrainerSession: action.data
            }
        }

        case GET_HISTORY_TRAINER_FT_RFT_HPA: {
            return {
                ...state,
                historyTrainerFtRftHpa: action.data
            }
        }

        case GET_RATE_AUTOPAY: {
            return {
                ...state,
                rateAutopay: action.data
            }
        }

        case GET_TRANSACTION_VOID: {
            return {
                ...state,
                transactionVoid: action.data
            }
        }

        case GET_REPORT_RETAINER: {
            return {
                ...state,
                reportRetainer: action.data
            }
        }

        case GET_DETAIL_RETAINER: {
            return {
                ...state,
                detailRetainer: action.data
            }
        }

        case GET_MEMBER_PACKAGE_TRAINER: {
            return {
                ...state,
                memberPackageTrainer: action.data
            }
        }

        case GET_EMPLOYEE_GUEST_CHECKIN: {
            return {
                ...state,
                employeeGuestCheckin: action.data
            }
        }

        case GET_FC_REV_PERFORMANCE: {
            return {
                ...state,
                fcRevPerformance: action.data
            }
        }

        case GET_FC_UNIT_PERFORMANCE: {
            return {
                ...state,
                fcUnitPerformance: action.data
            }
        }

        case GET_FC_PERFORMANCE_LIST: {
            return {
                ...state,
                fcPerformanceList: action.data
            }
        }

        case GET_LIST_TRANSFER_PACKAGE: {
            return {
                ...state,
                listTransfer: action.data
            }
        }

        case GET_LIST_EXTEND_PT: {
            return {
                ...state,
                listExtendPt: action.data
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default TransactionReducer;
